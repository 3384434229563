require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require('../support')
import "bootstrap.native"
import feather from 'feather-icons'
import "../scss/main.scss"
import "@hotwired/turbo-rails"
import Vue from 'vue/dist/vue.esm'
import store from '../store'
import apolloProvider from '../graphql'
import EnrollmentPage from '../pages/EnrollmentPage.vue'

import 'controllers'

document.addEventListener("turbo:load", () => {
  new Vue({
    el: '[data-behavior="vue"]',
    apolloProvider, 
    store,
    components: {
      EnrollmentPage
    }
  })

  BSN.initCallback(document.body);
  feather.replace(({ width: 15, height: 15 }))

  // Auto submit searchform when cleared
  document.getElementById("search-field")?.addEventListener("search", function(e) {
    const target = e.target as HTMLInputElement;
    
    if (target.value == "") {
      let action = target.form?.action;
      if (action !== undefined) {
        window.location.href = action;
      }
    }
  });
})


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
